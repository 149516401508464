.error-container {
  margin-top: 100px;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.error-container h3 {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 500;
  margin-bottom: 50px;
}

.error-container .image-loader {
  background-image: url("../assets/loader.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: auto;
  height: 300px;
  width: 300px;
}

.error-container .no-guilds {
  background-image: url("../assets/no-guild-perms.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: auto;
  height: 300px;
  width: 300px;
}
