#main .main-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}

#main .main-header h1 {
  font-size: 40px;
  text-align: center;
  color: white;
  font-weight: bold;
}

#main .main-header h2 {
  font-size: 15px;
  text-align: center;
  color: rgb(187, 187, 187);
}

#main .main-header a {
  background-color: transparent;
  border: 4px solid #2f8886;
  border-radius: 50px;
  display: inline-block;
  padding: 10px 15px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  margin: 10px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

#main .main-header a:hover {
  border: 4px solid #84c69b;
  font-size: 19px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

@media (max-width: 868px) {
  #main .main-header h1 {
    font-size: 35px;
  }

  #main .main-header h2 {
    font-size: 12px;
  }

  #main .main-header a {
    font-size: 15px;
  }

  #main .main-header a:hover {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  #main .main-header h1 {
    font-size: 30px;
  }
}

@media (max-width: 350px) {
  #main .main-header h1 {
    font-size: 25px;
  }

  #main .main-header h2 {
    font-size: 11px;
  }

  #main .main-header a {
    font-size: 12px;
  }

  #main .main-header a:hover {
    font-size: 13px;
  }
}
