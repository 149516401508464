#main .privacy {
  width: 100%;
  height: 100%;
  padding: 10px;
}

#main .privacy h1 {
  font-size: 40px;
  font-family: "poppins", sans-serif;
  text-decoration: underline;
}

#main .privacy h2 {
  font-size: 22px;
  font-family: "poppins", sans-serif;
}

#main .privacy p {
  font-size: 15px;
  font-family: "poppins", sans-serif;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  #main .privacy {
    text-align: center;
  }
}
