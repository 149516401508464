#main .commands {
  width: 100%;
  padding: 50px 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#main .commands .command {
  min-height: 50px;
  margin: 20px 0;
  background-color: var(--second-color);
  position: relative;
  cursor: pointer;
  transition: max-height 1s;
  -webkit-transition: max-height 1s;
  -ms-transition: max-height 1s;
  border-radius: 10px;
}

#main .commands .command .command-name {
  color: white;
  font-size: 20px;
  font-weight: 500;
  left: 20px;
  top: 10px;
  position: absolute;
}

#main .commands .command i {
  position: absolute;
  right: 12.5px;
  top: 12.5px;
  font-size: 25px;
  color: var(--fourth-color);
}

#main .commands .command .command-info {
  max-height: 0;
  width: 100%;
  background-color: var(--second-color);
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -ms-transition: all 1s ease;
  margin-top: 50px;
  overflow: hidden;
  border-radius: 0 0px 10px 10px;
}

#main .commands .command .command-info .command-description {
  color: white;
  font-size: 15px;
  padding-left: 20px;
  margin-top: 5px;
}

#main .commands .command .command-info .command-cooldown {
  color: white;
  font-size: 15px;
  padding-left: 20px;
  margin: 10px 0;
}

#main .commands .command .command-info .command-parameters {
  color: white;
  font-size: 15px;
  padding-left: 20px;
  margin: 0 0 10px 0;
}

#main .commands .command .command-info .command-parameters .sub-description {
  color: rgb(255, 255, 255);
  opacity: 0.8;
  font-size: 12px;
  padding-left: 5px;
}

@media (max-width: 868px) {
  #main .command-syntax,
  #main .commands {
    padding: 50px;
  }
}

@media (max-width: 550px) {
  #main .command-syntax,
  #main .commands {
    padding: 40px 20px;
  }

  #main .commands .command .command-info .command-description,
  #main .commands .command .command-info .command-cooldown,
  #main .commands .command .command-info .command-parameters {
    font-size: 13px;
  }

  #main .commands .command .command-info .command-parameters .sub-description {
    font-size: 10px;
  }
}

@media (max-width: 450px) {
  #main .command-syntax,
  #main .commands {
    padding: 40px 10px;
  }

  #main .commands .command .command-info .command-description,
  #main .commands .command .command-info .command-cooldown,
  #main .commands .command .command-info .command-parameters {
    font-size: 11px;
  }

  #main .commands .command .command-info .command-parameters .sub-description {
    font-size: 9px;
  }
}
