#main .dashboard form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

#main .dashboard form > button {
  width: 300px;
  background-color: rgb(255, 62, 62);
  height: 60px;
  border: 5px solid rgb(255, 17, 0);
  border-radius: 20px;
  font-size: 18px;
  font-weight: 600;
  margin: auto;
  transition: all 1s ease;
}

#main .dashboard form > button:hover {
  background-color: rgb(255, 36, 36);
}

#main .dashboard form .disabled {
  background-color: rgb(255, 62, 62);
  pointer-events: none;
  opacity: 0.5;
}

#main .dashboard form .category {
  margin-bottom: 50px;
  background-color: rgb(21, 23, 34);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  border-radius: 20px;
  position: relative;
}

#main .dashboard form .category h2 {
  font-size: 20px;
  text-align: left;
}

#main .dashboard form .category .arrow {
  position: absolute;
  right: 30px;
  top: 15px;
  font-size: 35px;
  cursor: pointer;
  transition: transform 1s ease;
}

#main .dashboard form .category .reversed {
  transform: rotate(180deg);
  transition: transform 1s ease;
}

#main .dashboard form .category:has(.reversed) .settings {
  opacity: 1;
  max-height: 2500px;
}

#main .dashboard form .category hr {
  margin: 15px 0;
  border: 0;
  height: 1px;
  background-color: rgb(46, 46, 46);
}

#main .dashboard form .category .settings {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

  transition: all 2s ease;
  -webkit-transition: all 2s ease;
  -ms-transition: all 2s ease;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  position: relative;
}

#main .dashboard form .category .settings .setting {
  width: 30%;
  max-width: 500px;
  min-height: 80px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  background-color: rgb(33, 35, 43);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  position: relative;
}

#main .dashboard form .category .settings .setting .prop {
  position: absolute;
  right: 20px;
  width: 120px;
}

#main .dashboard form .category .settings .setting .time {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  row-gap: 10px;
}

#main .dashboard form .category .settings .setting .blocked,
#main .dashboard form .category .settings .setting .blocked input {
  cursor: not-allowed;
  pointer-events: none;
}

#main .dashboard form .category .settings .setting .time input {
  background-color: transparent;
  border: white 1px solid;
  border-radius: 5px;
  padding-left: 5px;
  height: 25px;
  position: relative;
  margin: 0 5px;
  right: 0;
  width: 30px;
  margin-top: 5px;
}

#main .dashboard form .category .settings .setting .blocked input {
  border: rgb(106, 106, 106) 1px solid;
  color: rgb(106, 106, 106);
}

#main .dashboard form .category .settings .setting .prop > label,
#main .dashboard form .category .settings .setting .prop > button,
#main .dashboard form .category .settings .setting .prop > div {
  float: right;
}

#main .dashboard form .category .settings .setting h3 {
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
}

#main .dashboard form .category .settings .setting p {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.8px;
  opacity: 0.8;
}

#main .dashboard form .category .settings .setting .time p {
  margin-top: 5px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.8px;
  opacity: 1;
}

#main .dashboard form .category .settings .setting .blocked p {
  opacity: 0.2;
}

@media (max-width: 1500px) {
  #main .dashboard form {
    width: 90%;
  }

  #main .dashboard form .category .settings .setting {
    width: 45%;
  }
}

@media (max-width: 1000px) {
  #main .dashboard form .category .settings .setting {
    width: 60%;
  }
}

@media (max-width: 900px) {
  #main .dashboard form .category .settings .setting {
    width: 70%;
  }
}

@media (max-width: 800px) {
  #main .dashboard form .category .settings .setting {
    width: 90%;
  }
}

@media (max-width: 700px) {
  #main .dashboard form {
    width: 95%;
  }

  #main .dashboard form .category .settings .setting {
    width: 100%;
  }
}

@media (max-width: 600px) {
  #main .dashboard form .category .settings .setting h3 {
    font-size: 16px;
    margin-top: 0px;
  }

  #main .dashboard form .category .settings .setting p {
    font-size: 12px;
    margin-top: 10px;
  }

  #main .dashboard form {
    width: 95%;
  }

  #main .dashboard form .category .settings .setting {
    width: 100%;
    margin: 20px 0px;
  }

  #main .dashboard form .category .settings .setting .prop:has(button),
  #main .dashboard form .category .settings .setting .prop:has(div) {
    position: relative;
    right: 0;
    width: 100%;
    margin-top: 20px;
  }

  #main .dashboard form .category .settings .setting .prop button {
    width: 100%;
  }

  #main .dashboard form .category .settings .setting .prop > button,
  #main .dashboard form .category .settings .setting .prop > div {
    float: none;
  }
}

#main .css-r6z5ec {
  --popper-transform-origin: bottom left !important;
}

#main .special-scroll::-webkit-scrollbar {
  width: 8px;
  background: #0d141f;
  border-radius: 5px;
}

#main .special-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
}

#main .special-scroll::-webkit-scrollbar-thumb {
  background: #525861;
  border-radius: 5px;
}
