#main .guilds {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
}

#main .guilds .guild {
  width: 50%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgb(33, 35, 43);
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px;
  margin: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

#main .guilds .guild h2 {
  color: white;
  opacity: 0.9;
  font-size: 19px;
  margin-left: 15px;
}

#main .guilds .guild img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

#main .guilds .guild .default-icon {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding-left: 23px;
  padding-top: 13px;
  font-size: 24px;
  background-color: #36393f;
}

#main .guilds .guild a {
  color: rgb(47, 216, 47);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.2px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 170px;
  height: 50px;
  text-decoration: none;
  position: absolute;
  right: 15px;
  transition: color 1s ease;
}

#main .guilds .guild .invite {
  color: rgb(255, 79, 79) /*rgb(255, 52, 52)*/;
}

#main .guilds .guild:hover a {
  color: rgb(251, 255, 0);
  transition: color 1s ease;
}

#main .guilds .guild:hover .invite {
  color: rgb(255, 79, 246);
  transition: color 1s ease;
}

#main .guilds .guild:hover {
  background-color: rgb(38, 41, 49);
  transition: background-color 0.5s ease;
}

@media (max-width: 1450px) {
  #main .guilds .guild {
    width: 100%;
  }
}

@media (max-width: 650px) {
  #main .guilds .guild a p {
    display: none;
  }
}

@media (max-width: 500px) {
  #main .guilds .guild {
    margin: 10px 0;
  }

  #main .guilds .guild h2 {
    font-size: 16px;
  }

  #main .guilds .guild img {
    height: 50px;
    width: 50px;
  }

  #main .guilds .guild .default-icon {
    height: 50px;
    width: 50px;
    padding-left: 20px;
    padding-top: 10px;
    font-size: 21px;
  }
}

@media (max-width: 450px) {
  #main .guilds .guild h2 {
    font-size: 15px;
  }

  #main .guilds .guild img {
    height: 40px;
    width: 40px;
  }

  #main .guilds .guild .default-icon {
    height: 40px;
    width: 40px;
    padding-left: 15px;
    padding-top: 8px;
    font-size: 17px;
  }

  #main .guilds .guild a svg {
    font-size: 17px !important;
  }
}

@media (max-width: 400px) {
  #main .guilds .guild h2 {
    font-size: 13px;
  }
}
