@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

html,
body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background: -webkit-linear-gradient(to left, #081c35, #0c1222) !important;
  background: linear-gradient(to left, #081c35, #0c1222) !important;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#main {
  position: relative;
  margin-top: 95px;
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}

/* @media (max-width: 868px) {
  #main {
    padding: 10px;
  }
} */

.hidden {
  display: none !important;
}

:root {
  --first-color: #16213e;
  --second-color: #0f3460;
  --third-color: #754cb3; /* 533483 */
  --fourth-color: #e94560;
  /* --first-color: #1a1a2e;
  --second-color: #16213e;
  --third-color: #0f3460;
  --fourth-color: #e94560; */
}
