/* home page top wave */

#main .home-top {
  background-color: var(--first-color);
  color: white;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  z-index: 2;
}

#main .home-top div {
  width: 47.5%;
}

#main .home-top .animated-letters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#main .home-top .animated-letters img {
  max-width: 14%;
  margin: 0 1.5%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
  transform: translateY(5px);
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-25px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-25px);
  }
}

#main .home-top .animated-letters hr {
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(182, 182, 182, 0),
    var(--fourth-color),
    rgba(182, 182, 182, 0) /* rgba(182, 182, 182, 0),
    rgba(218, 218, 218, 0.75),
    rgba(182, 182, 182, 0) */
  );
  margin-top: 10px;
}

@media (max-width: 700px) {
  #main .home-top div {
    width: 90%;
  }

  #main .home-top .animated-letters {
    display: none;
  }
}

#main .home-top h1 {
  text-align: center;
  font-family: "poppins";
  font-size: 40px;
  font-weight: 600;
}

#main .home-top h2 {
  text-align: center;
  font-family: "poppins";
  font-size: 20px;
}

#main .home-top .btns {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

#main .home-top .btns button {
  width: 40%;
  height: 40px;
  background-color: transparent;
  border: 5px solid var(--third-color);
  border-radius: 20px;
  font-size: 16px;
  transition: border 1s ease;
}

@media (max-width: 400px) {
  #main .home-top .btns button {
    font-size: 14px;
    width: 45%;
  }
}

#main .home-top .btns button:hover {
  border: 5px solid var(--fourth-color);
}

/* home page content between waves */

#main .content {
  margin-top: 10px;
  width: 100%;
}

#main .content .flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

#main .content .flex .flex-div {
  padding: 5px;
  width: 90%;
  margin: 20px 5%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
}

#main .content .flex .flex-div div {
  width: 50%;
  height: 100%;
  min-height: 500px;
}

#main .content .flex .flex-div .text {
  padding: 50px;
  padding-right: 120px;
}

#main .content .flex .flex-div .images {
  display: flex;
  justify-content: center;
  align-items: center;
}

#main .content .flex .flex-div .images img,
#main .content .flex .flex-div .images link {
  width: 70%;
  height: 40%;
}

@media (max-width: 1000px) {
  #main .content .flex .flex-div {
    width: 95%;
    margin: 10px 2.5%;
  }

  #main .content .flex .flex-reverse {
    flex-direction: column-reverse;
  }

  #main .content .flex .flex-forward {
    flex-direction: column;
  }

  #main .content .flex .flex-div div {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  #main .content .flex .flex-div .text {
    text-align: center;
    padding-right: 50px;
  }

  #main .content .flex .flex-div .images {
    min-height: 0px;
    padding: 5px;
  }

  #main .content .flex .flex-div .images img {
    width: 100%;
  }
}

#main .content .flex .flex-div .text h3 {
  color: white;
  font-size: 30px;
  font-weight: 700;
}

#main .content .flex .flex-div .text p {
  opacity: 0.85;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.2px;
}

#main .content .flex .flex-div .text p a {
  color: #50a1ff;
  text-decoration: underline;
  font-weight: 500;
}

#main .content .flex .flex-div .text p strong {
  letter-spacing: 0.8px;
  font-weight: 700;
}

#main .content .flex .flex-div .text p a:hover {
  color: #1f88ff;
}

@media (max-width: 1400px) and (min-width: 1000px) {
  #main .content .flex .flex-div .text {
    padding-right: 50px;
  }

  #main .content .flex .flex-div .text h3 {
    font-size: 25px;
  }

  #main .content .flex .flex-div .text p {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  #main .content .flex .flex-div .text {
    padding: 10px;
  }

  #main .content .flex .flex-div .text h3 {
    font-size: 25px;
  }

  #main .content .flex .flex-div .text p {
    font-size: 14px;
  }
}
